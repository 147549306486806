.root {
  display: flex;
  width: 100%;
  margin-bottom: 23px;
  max-width: 633px;
}

.item {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.label {
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: 0.35px;
  text-align: left;
  color: #b5b9bb;
  margin-left: 14px;
}
.label.completed {
  color: #666b6e;
}

@media (max-width: 767px) {
  .root {
    margin: 0;
    overflow: hidden;
  }
  .item {
    white-space: nowrap;
  }
  .item + .item {
    margin-left: 20px;
  }
  .label {
    font-size: 12px;
    margin-left: 10px;
  }
  .alignStart {
    justify-content: flex-start;
  }
  .alignCenter {
    justify-content: center;
  }
  .alignEnd {
    justify-content: flex-end;
  }
}
