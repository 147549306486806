.root {
  width: 40px;
  height: 40px;
  position: relative;
}

.root .seg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.root .seg:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #f196c6;
  border-radius: 100%;
  -webkit-animation: fadeDelay 1.2s infinite ease-in-out both;
  animation: fadeDelay 1.2s infinite ease-in-out both;
}
.root .seg2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.root .seg3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.root .seg4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.root .seg5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.root .seg6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.root .seg7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.root .seg8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.root .seg9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.root .seg10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.root .seg11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.root .seg12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.root .seg2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.root .seg3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.root .seg4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.root .seg5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.root .seg6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.root .seg7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.root .seg8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.root .seg9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.root .seg10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.root .seg11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.root .seg12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes fadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes fadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
