.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
    text-align: center;
    align-self: center;
}

h1.label {
  font-weight: bold;
  font-size: 1.25rem;
}

h2.label {
font-weight: normal;
font-size: 1.25rem;
}

p.label {
  width: 70%;
  padding-bottom: 1rem;
}

.positioning {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    align-self: center;
}

.btnPositioning {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn {
    height: 50px;
    width: 275px;
    margin: 1.5rem 0.5rem 0 0.5rem;
}

@media (max-width: 900px) {
  .positioning {
    flex-direction: column;
  }
}


@media (max-width: 320px) {
  .root {
    width: 100%;
  }
}
