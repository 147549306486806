.root {
  position: absolute;
  top: -106px; /* 91px height + 15px padding */
  right: -150px; /* 50% width - (20px / 2) -- half icon width */

  width: 320px;
  height: 91px;
  border: solid 1px #b5b9bb;
  border-radius: 4px;
  background-color: #f7f8f8;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  line-height: 1.29;
  letter-spacing: 0.35px;
  text-align: center;
}

.root:after,
.root:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

.root:after {
  bottom: -20px;
  border-color: #f7f8f8 transparent transparent transparent;
  border-width: 10px;
}

.root:before {
  bottom: -22px;
  border-color: #b5b9bb transparent transparent transparent;
  border-width: 11px;
}

@media (max-width: 1023px) {
  .root {
    right: -5px;
  }
  .root:after {
    right: 5px;
  }
  .root:before {
    right: 4px;
  }
}
