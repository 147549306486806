.root {
  position: relative;
  margin-left: 17px;
  background-color: #f7f8f8;
  padding: 20px;
  height: 431px;
  min-width: 309px;
}

.root.specific {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(176, 190, 207, 0.29);
  background-color: #e6eaf1;
}

.title {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
  text-align: left;
  color: #6286a4;
}

.item {
  display: flex;
  margin-top: 20px;
}

.icon {
  width: 45px;
  height: 58px;
}

.seg {
  margin-left: 20px;
  max-width: 200px;
}

.heading {
  display: flex;
  margin-bottom: 4px;
}

.headingText {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.13;
  letter-spacing: 0.4px;
}

.margLeft {
  margin-left: 11px;
}

.msg {
  font-size: 14px;
  line-height: 1.29;
  text-align: left;
}

@media (max-width: 767px) {
  .root {
    height: 350px;
    margin: 0;
  }
  .headingText {
    font-size: 14px;
  }
  .msg {
    font-size: 12px;
  }
  .seg {
    max-width: 100%;
    margin-left: 10px;
  }
  .icon {
    width: 35px;
    height: 45px;
  }
}
