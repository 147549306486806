.input {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  -webkit-appearance: none;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #666b6e;
  background-color: #ffffff;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: #666b6e;
}

.input:focus {
  box-shadow: 0 0 5px 0 rgba(229, 0, 107, 0.5);
  border: solid 2px #f196c6;
}

.input.errored {
  box-shadow: 0 0 5px 0 #e5006b;
  border: solid 1px #e5006b;
}

.circle {
  margin-left: 10px;
}

@media (max-width: 767px) {
  .root {
    max-width: 320px;
  }
}
