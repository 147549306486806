.root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 40px;
  box-shadow: 0px 2px 3px 0 rgba(0, 0, 0, 0.1);
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
}

.secondary {
  width: 100%;
  height: 100%;
  border-radius: 40px;
  box-shadow: 0px 2px 3px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #666b6e;
  background-color: transparent;
  font-size: 16px;
  line-height: 1.88;
  color: #666b6e;
}

.primary.hovered:not(.disabled),
.secondary.hovered:not(.disabled) {
  border: solid 1px #f196c6;
  background-color: #ffffff;
  color: #f196c6;
}

.front {
  width: 188px;
  height: 40px;
  border-radius: 20px;
  box-shadow: 0px 2px 3px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #f196c6;
  background-color: #f196c6;
  font-size: 16px;
  line-height: 1.88;
  color: #ffffff;
}

.primary.hovered:not(.disabled),
.front.hovered:not(.disabled) {
  border: solid 1px #f196c6;
  background-color: #ffffff;
  color: #f196c6;
}

.primary,
.white:not(.disabled).hovered {
  background-color: #f196c6;
  color: #ffffff;
}

.white {
  background-color: #ffffff;
}

.disabled {
  opacity: 0.4;
}

.small {
  width: 200px;
  height: 40px;
  font-size: 16px;
  line-height: 1.88;
}

.large {
  width: 274px;
}

.tiny {
  width: 154px;
  height: 24px;
}

.tiny-wide {
  width: 274px;
  height: 24px;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 14px;
}

@media (max-width: 767px) {
  .primary {
    font-size: 16px;
  }
  .secondary {
    font-size: 14px;
  }
}
