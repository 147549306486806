.root {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #e1e3e4;
  background-color: #ffffff;
  padding: 40px 120px 40px 40px;
  align-items: center;
}

.segTop {
  display: flex;
  border-bottom: 1px solid #e1e3e4;
  padding-bottom: 54px;
}

.segBottom {
  margin: 38px 0 0 130px;
}

.img {
  width: 100px;
  height: 100px;
  margin-top: 20px;
  margin-right: 30px;
  opacity: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.btn {
  height: 50px;
  width: 338px;
  margin-top: 30px;
}

@media (max-width: 1023px) {
  .root {
    padding: 20px;
    width: 400px;
  }
  .segTop {
    padding-bottom: 30px;
  }
  .segBottom {
    margin: 20px 0 0 0;
  }
  .img {
    display: none;
  }
}

@media (max-width: 767px) {
  .root {
    width: initial;
  }
  .segTop {
    justify-content: unset;
  }
  .btn {
    width: 320px;
  }
}

@media (max-width: 320px) {
  .root {
    padding: 8px;
  }
  .btn {
    width: 270px;
    margin-bottom: 20px;
  }
  .segBottom {
    max-width: 270px;
  }
}
