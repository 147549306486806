.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 30px;
  width: 338px;
}

a {
  padding-left: 25px;
}

@media (max-width: 320px) {
  .root {
    width: 100%;
  }
}
