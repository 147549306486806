.root {
  margin-top: 40px;
}

.title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

.body {
  font-size: 16px;
  margin-bottom: 25px;
}

@media (max-width: 1023px) {
  .root {
    width: 100%;
    padding: 20px;
  }
}

@media (max-width: 767px) {
  .root {
    padding: 20px;
  }
}

@media (max-width: 320px) {
  .root {
    padding: 20px 8px 20px 8px;
  }
}
