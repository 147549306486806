/* changes for sitecore integration */
.content-area {
  padding: 0;
  max-width: none;
  margin: 0;
}

.text-container {
  padding: 0;
}

/* ordinary css */
:root {
  --technical-grey: #666b6e;
  --grey-5: #f7f8f8;
  --grey-10: #f0f1f2;
  --grey-20: #e1e3e4;
  --grey-50: #b5b9bb;
  --white: #ffffff;
  --green: #45aa18;
  --pink: #f196c6;
  --arctic-blue: #6286a4;
  --arctic-blue-15: #e6eaf1;
  --error: #ed2b43;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

@font-face {
  font-family: "CeraPRO";
  src: url("./assets/fonts/CeraPro/TypeMates\ -\ CeraPRO-Light.otf");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "CeraPRO";
  src: url("./assets/fonts/CeraPro/CeraPRO-Regular.otf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "CeraPRO";
  src: url("./assets/fonts/CeraPro/CeraPRO-Bold.otf");
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "CeraPRO", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  color: #666b6e;
}

input {
  font-family: "CeraPRO-Regular", "CeraPRO";
}

textarea:focus,
input:focus,
select:focus {
  outline: none;
}

::-webkit-datetime-edit-day-field:focus,
::-webkit-datetime-edit-month-field:focus,
::-webkit-datetime-edit-year-field:focus {
  background-color: transparent;
  color: #666b6e;
}
::-webkit-outer-spin-button,
::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
  display: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}

/* react-datepicker overrides */
.react-datepicker__current-month {
  color: #666b6e !important;
}
.react-datepicker__header {
  background-color: #ffffff !important;
}
.react-datepicker__day-name {
  color: #666b6e !important;
}
