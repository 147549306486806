.circle {
  width: 20px;
  min-width: 20px;
  height: 20px;
  opacity: 0;
  transition: all 0.2s;
}
.circle.hasIdx {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  background: #b5b9bb;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  color: #ffffff;
}
.circle.show {
  opacity: 1;
}
.circle.completed {
  background: url("../../../assets/IC_Tick_Green.svg");
}
.circle.errored {
  background: url("../../../assets/IC_Cross_Magenta.svg");
}
.circle.fixed {
  background: url("../../../assets/IC_Tick_Blue.svg");
}
