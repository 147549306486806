.root {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 338px;
}

.title {
  font-weight: bold;
  font-size: 24px;
}

.text {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.text.bold {
  font-weight: bold;
}

.spacer {
  height: 20px;
  width: 100%;
}

@media (max-width: 767px) {
  .root {
    width: 320px;
  }
}

@media (max-width: 320px) {
  .root {
    width: 260px;
  }
}
