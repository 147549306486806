.root {
  padding-bottom: 30px;
  border-bottom: 1px solid #e1e3e4;
}

.checks {
  display: flex;
  align-items: center;
}

.check + .check {
  margin-left: 15px;
}

.spacer {
  height: 30px;
  width: 100%;
}

.ddSeg {
  margin-top: 30px;
}

.back {
  height: 20px;
  width: 20px;
}
