.topSeg {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 640px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #e1e3e4;
  background-color: #ffffff;
  padding: 20px 0;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 640px) {
  .errorContainer {
    max-width: 640px;
  }
}

.btn {
  height: 50px;
  width: 338px;
  margin-top: 30px;
}

.checks {
  margin: 30px 0;
}

@media (max-width: 767px) {
  .btn {
    width: 320px;
  }
}

@media (max-width: 320px) {
  .form {
    width: 100%;
  }
  .btn {
    width: 260px;
  }
  .checks {
    width: 270px;
  }
}
