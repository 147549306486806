.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.padTop {
  padding-top: 20px;
}

.padTopLarge {
  padding-top: 100px;
}
