.title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  color: #666b6e;
  margin-top: 28px;
  margin-bottom: 16px;
}

.content {
  display: flex;
  flex-direction: column;
}

.seg {
  display: flex;
}

@media (max-width: 1023px) {
  .root {
    width: 100%;
    padding: 20px;
  }
}

@media (max-width: 767px) {
  .root {
    padding: 0;
  }
  .seg {
    flex-direction: column;
  }
  .content {
    padding: 20px;
  }
  .title {
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0px;
    color: #5f6062;
    margin-top: 0;
    text-align: center;
  }
}

@media (max-width: 320px) {
  .content {
    padding: 20px 8px 20px 8px;
  }
}
