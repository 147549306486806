.root {
  margin-top: 32px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.divide {
  font-size: 12px;
  line-height: 2;
  color: #afafb0;
}

.placeholder {
  font-size: 12px;
  color: #b5b9bb;
  padding: 2px 0 0 8px;
}

.calcSeg {
  display: flex;
  flex-direction: column;
  width: 180px;
  height: 50px;
  border: solid 1px #666b6e;
  background-color: #ffffff;
}
.calcSeg.disabled {
  background-color: #f7f8f8;
}

.inputGroup {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.x {
  width: 7px;
  height: 16px;
  font-size: 12px;
  line-height: 2;
  letter-spacing: 0.3px;
  color: #b5b9bb;
  display: flex;
  align-items: center;
}

.input {
  -webkit-appearance: none;
  box-sizing: border-box;
  width: 30px;
  border: none;
  background-color: transparent;
  border-radius: 0;
  border-bottom: solid 2px #b5b9bb;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: center;
  color: #666b6e;
}

.input:focus {
  border-bottom: solid 2px #f196c6;
}

.input.errored {
  border-bottom: solid 2px #e5006b;
}

.cont {
  display: flex;
  align-items: center;
}

.circle {
  margin-left: 10px;
}

@media (max-width: 767px) {
  .root {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
