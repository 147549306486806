.root {
  display: flex;
  align-items: center;
}

.seg {
  position: relative;
  width: 130px;
  height: 50px;
  border: solid 1px #666b6e;
  background-color: #ffffff;
  overflow: hidden;
  padding: 8px;
}

.seg.disabled {
  background-color: #f7f8f8;
}
.seg.focused {
  box-shadow: 0 0 5px 0 rgba(229, 0, 107, 0.5);
  border: solid 2px #f196c6;
}
.seg.errored {
  box-shadow: 0 0 5px 0 #e5006b;
  border: solid 1px #e5006b;
}

.input {
  -webkit-appearance: none;
  box-sizing: border-box;
  border: none;
  width: 100%;
  background-color: transparent;
  border-radius: 0;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: left;
  color: #666b6e;
}

.placeholder {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 9.37px;
  letter-spacing: normal;
  text-align: left;
  color: #afafb0;
}

.circle {
  margin-left: 10px;
}
