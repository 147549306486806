.root {
  height: 800px;
  width: 100%;
  background: url("../../../assets/full-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.sprRoot {
  width: 570px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0.9;
  background-color: #6286a4;
  color: #ffffff;
  padding: 30px;
  z-index: 2;
  position: relative;
  top: -585px;
  left: 7%;
}

.title {
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
}

.body {
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 20px;
}

.subTitle {
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: 0.45px;
  margin-bottom: 30px;
}

.productImg {
  margin-left: 500px;
  background-size: contain;
  background-position: center;
}

@media (max-width: 1023px) {
  .root {
    height: 402px;
  }
  .sprRoot {
    width: 390px;
    height: 340px;
    margin-left: 20px;
    position: relative;
    top: -372px;
    left: 3%;
  }
  .title {
    font-size: 30px;
  }
  .subTitle {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.4px;
  }

  .productImg {
    margin-left: 430px;
    background-size: contain;
    background-position: center;
  }
}

@media (max-width: 767px) {
  .root {
    min-height: 85vh;
    background: url("../../../assets/full-background-mobile.jpg");
    display: flex;
    flex-direction: column;
  }

  .sprRoot {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 20px;
    position: inherit;
    top: 0px;
  }
  .title {
    text-align: left;
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 0px;
  }
  .subTitle {
    text-align: left;
    font-size: 16px;
    letter-spacing: 0.4px;
    margin-bottom: 20px;
  }
  .productImg {
    margin-left: 0px;
  }
}
