.root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.seg {
  position: relative;
  display: flex;
  margin-top: 40px;
  margin-bottom: 30px;
}

.product {
  display: flex;
  align-items: center;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #666b6e;
  padding: 20px;
  background: url("../../../assets/background.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 431px;
  max-width: 632px;
}

.smallHeader {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.9px;
  text-align: center;
  padding: 0 100px;
  user-select: text;
}

.messageSection {
  word-break: break-word;
}

.name {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  color: #ffffff;
}

.code {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 240px;
  height: 40px;
  opacity: 0.86;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #6286a4;
  background-color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 2.4px;
  margin-top: 10px;
}

.link {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: #ffffff;
  text-decoration: underline;
  cursor: pointer;
}

.ukLink {
  text-decoration: underline;
  color: #0000ee;
  cursor: pointer;
}

.img {
  margin-left: 40px;
  height: 300px;
  width: 300px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.text {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.4px;
}

.store {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.appStore + .appStore {
  margin-left: 20px;
}

@media (max-width: 767px) {
  .root {
    align-items: initial;
  }
  .seg {
    flex-direction: column;
    margin-top: 13px;
  }
  .code {
    justify-content: space-evenly;
  }
  .product {
    flex-direction: column;
    width: 100%;
  }
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .img {
    margin: 16px 0 16px 0;
  }
  .link {
    position: initial;
  }
  .name {
    text-align: center;
  }
  .text {
    text-align: center;
    margin: 0 17px;
  }
  .store {
    justify-content: center;
  }
}
