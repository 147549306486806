.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.67;
  text-align: left;
  color: #5f6062;
}
