.root {
  padding-bottom: 30px;
  border-bottom: 1px solid #e1e3e4;
}

.seg {
  margin-bottom: 30px;
}

.consumption {
  display: flex;
  flex-direction: column;
}

.water {
  display: flex;
  justify-content: space-between;
}

.attention {
  max-width: 338px;
  color: #ff003b;
  font-size: 14px;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .root {
    padding-bottom: 10px;
  }
}

@media (max-width: 320px) {
  .attention {
    max-width: 260px;
  }
}
