.root {
  width: 480px;
  height: 480px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width: 1023px) {
  .root {
    width: 360px;
    height: 360px;
  }
}

@media (max-width: 767px) {
  .root {
    width: 300px;
    height: 300px;
  }
}
