.inputs {
  display: flex;
  align-items: center;
}

.title {
  margin-right: 30px;
}

.input {
  width: 40px;
  height: 50px;
  font-size: 24px;
  line-height: 1.68;
  letter-spacing: 0.6px;
  text-align: center;
  color: #666b6e;
  border: none;
  flex-shrink: 0;
  border-radius: 0;
}

.input:nth-child(1),
.input:nth-child(2),
.input:nth-child(3) {
  border-right: 1px solid #666b6e;
}

.input.focused {
  box-shadow: 0 0 5px 0 rgba(241, 150, 198, 0.5);
  border: solid 2px #f196c6;
}

.chunk {
  width: 162px;
  height: 52px;
  border: 1px solid #666b6e;
}

.chunk.errored {
  box-shadow: 0 0 5px 0 #e5006b;
  border: solid 1px #e5006b;
}

.divide {
  height: 2px;
  width: 10px;
  background-color: #666b6e;
  margin: 0 4px;
}

.productCode {
  display: flex;
  align-items: center;
}

.ci {
  margin-left: 10px;
}

@media (max-width: 767px) {
  .divide {
    width: 8px;
  }
  .input {
    font-size: 20px;
  }
}

@media (max-width: 375px) {
  .title {
    margin-right: 0;
  }
  .ci {
    display: none;
  }
  .chunk.ok {
    box-shadow: 0 0 5px 0 #45aa18;
    border: solid 1px #45aa18;
  }
}

@media (max-width: 320px) {
  .input {
    width: 35px;
    height: 40px;
  }
  .chunk {
    width: 142px;
    height: 42px;
    border: 1px solid #666b6e;
  }
}
