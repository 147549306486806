.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  margin-top: 20px;
  padding: 10px;
  font-size: 16px;
  line-height: 1.5;
  border-bottom: 2px solid #666b6e;
  max-width: 338px;
}

.select {
  width: 338px;
  max-height: 400px;
  min-height: 46px;
  overflow-y: auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #666b6e;
  background-color: #ffffff;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.4px;
  margin-top: 20px;
  position: relative;
}

.item {
  padding: 10px;
}

.item.selected {
  background-color: #f196c6;
  color: #ffffff;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.item:active,
.item:hover,
.item:focus {
  background-color: #f196c6;
  color: #ffffff;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  opacity: 0.5;
}

.icon {
  height: 10px;
  width: 10px;
  transition: transform 0.2s;
}

.icon.open {
  transform: rotate(180deg);
}

.search {
  -webkit-appearance: none;
  box-sizing: border-box;
  border: none;
  width: 100%;
  background-color: transparent;
  border-radius: 0;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: left;
  color: #666b6e;
}

.search::placeholder {
  color: #666b6e;
}

.noRes {
  margin-top: 20px;
}

@media (max-width: 767px) {
  .root {
    max-width: 320px;
  }
  .select {
    width: 320px;
  }
}

@media (max-width: 320px) {
  .root {
    max-width: 240px;
  }
  .select {
    width: 240px;
  }
}
