.root {
  width: 100%;
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.btns {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btnPrim {
  width: 444px;
  height: 50px;
  margin-top: 60px;
}

.btnSec {
  margin-top: 32px;
}

@media (max-width: 767px) {
  .main {
    flex-direction: column;
  }
  .btnPrim {
    width: 300px;
    height: 40px;
    margin-top: 20px;
  }
}
