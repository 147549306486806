.root {
  margin-top: 100px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 40px;
}

.items {
  display: flex;
  justify-content: center;
}

.item {
  width: 310px;
  height: 400px;
  max-height: 400px;
  border: solid 1px #e1e3e4;
  background-color: #f7f8f8;
}

.item + .item {
  margin-left: 15px;
}

.img {
  width: 100%;
  height: 160px;
  border: solid 1px #e1e3e4;
}

.content {
  height: 240px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.name {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.67;
  color: #5f6062;
  margin-bottom: 7px;
}

.desc {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.4px;
}

.links {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.storeLink {
  height: 37px;
  width: 120px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .root {
    display: none;
  }
}
