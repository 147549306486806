.root {
  display: flex;
  width: 100%;
  height: 120px;
  align-items: center;
  box-shadow: 0 0 6px 0 #e5006b;
  border: solid 1px #e5006b;
  background-color: #ffffff;
  margin-bottom: 17px;
  padding: 0 60px;
  justify-content: center;
}

.icon {
  width: 70px;
  height: 70px;
  margin-right: 35px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.17;
  letter-spacing: 0.6px;
  text-align: left;
  color: #e5006b;
}

.body {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: left;
}

@media (max-width: 768px) {
  .root {
    padding: 0 20px;
  }
}

@media (max-width: 375px) {
  .root {
    height: 100px;
  }
  .icon {
    height: 60px;
    width: 60px;
  }
  .title {
    font-size: 22px;
  }
  .body {
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  .root {
    height: 80px;
    margin-bottom: 10px;
  }
  .icon {
    height: 50px;
    width: 50px;
  }
  .title {
    font-size: 20px;
  }
  .body {
    font-size: 12px;
  }
}
