.root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.frame {
  position: absolute;
  max-height: 80%;
  max-width: 80%;
  top: 10%;
  box-shadow: 0px 6px 12px #00000029;
  background-color: white;
}

.content {
  text-align: left;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 50px;
}

.close {
  position: absolute;
  height: 40px;
  width: 40px;
  right: -20px;
  top: -20px;
  border-radius: 50%;
  box-shadow: 3px 3px 10px #00000059;
  background: url("../../../../assets/Close.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.interactionDisabled {
  pointer-events: none;
  touch-action: none;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

@media (max-width: 767px) {
  .close {
    height: 30px;
    width: 30px;
    right: -15px;
    top: -15px;
  }
}
