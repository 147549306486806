.root {
  width: 340px;
  height: 50px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #666b6e;
  background-color: #ffffff;
  background-image: url("../../../assets/ic_event.svg");
  background-position: 308px 20px;
  background-repeat: no-repeat;
  background-size: 18px 16px;
  color: transparent; /* hide unformatted date */
  user-select: none;
}

.root::selection {
  background: transparent;
  color: transparent;
}

.selected {
  background-color: #f196c6;
  color: #ffffff;
  border-radius: 0;
}
.unselected {
  background-color: #ffffff;
  color: #666b6e;
}
.future {
  color: #b5b9bb;
}
.selected:hover,
.unselected:hover {
  background-color: #f196c6;
  color: #ffffff;
  border-radius: 0;
  opacity: 0.5;
}

.label {
  font-size: 12px;
  color: #afafb0;
  position: absolute;
  top: 5px;
  left: 11px;
  pointer-events: none;
}

/* show formatted date */
.date {
  position: absolute;
  bottom: 9px;
  left: 11px;
  font-size: 16px;
  letter-spacing: 0.4px;
  color: #666b6e;
  pointer-events: none;
}

:focus {
  outline: none;
}

@media (max-width: 320px) {
  .root {
    width: 300px;
  }
}
