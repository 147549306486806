.root {
  display: flex;
  align-items: center;
}

.checkbox {
  display: flex;
  align-self: flex-start;
  padding: 10px 0;
  position: relative;
  flex-shrink: 0;
  height: 20px;
  width: 20px;
  background-image: url("../../../assets/checkbox-unchecked.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.checkbox.checked {
  background-image: url("../../../assets/checkbox-checked.svg");
}

.label {
  margin-left: 8px;
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: 0.35px;
  text-align: left;
  max-width: 260px;
}

@media (min-width: 1024px) {
  .checkbox {
    transition: all 0.2s;
  }
}
