.root {
  font-size: 36px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.9px;
  text-align: center;
}

@media (max-width: 767px) {
  .root {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 0px;
    color: #5f6062;
  }
}
