.root {
  display: flex;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #e1e3e4;
  background-color: #ffffff;
  padding: 40px 120px 40px 40px;
}

.img {
  width: 100px;
  height: 100px;
  margin-top: 20px;
  margin-right: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0;
}

.img.show {
  opacity: 1;
  transition: opacity 0.2s;
}

.btn {
  height: 50px;
  width: 338px;
  margin-top: 30px;
}

.overview {
  display: none;
}

.spacer {
  height: 40px;
  width: 100%;
}

@media (max-width: 1023px) {
  .root {
    padding: 20px;
    max-width: 400px;
  }
  .img {
    display: none;
  }
}

@media (max-width: 767px) {
  .root {
    padding: 20px;
    width: 100%;
    max-width: initial;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .overview {
    display: flex;
  }
}

@media (max-width: 320px) {
  .root {
    padding: 0;
  }
  .content {
    width: 100%;
    padding: 10px;
  }
  .btn {
    width: 300px;
    margin-bottom: 20px;
  }
}
