.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 75px;
}

.title {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
}

.text {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: center;
  margin-bottom: 20px;
  padding: 0 80px;
}

.link {
  text-decoration: none;
}

@media (min-width: 767px) {
  .root {
    max-width: 1005px;
  }
}
