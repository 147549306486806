.root {
  font-size: 24px;
  line-height: 1.68;
  letter-spacing: 0.6px;
  text-align: center;
}

@media (max-width: 767px) {
  .root {
    font-size: 16px;
    margin-top: 10px;
  }
}
