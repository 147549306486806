.root {
  width: 990px;
}

.stepTitleContainer {
  display: flex;
  text-transform: uppercase;
  justify-content: center;
  font-size: 15px;
}

.stepTwo {
  border-left: 1px solid #666b6e;
  border-right: 1px solid #666b6e;
  padding: 0 20px;
}

.step {
  padding: 0 20px;
}

.title {
  font-weight: bold;
  font-size: 22px;
  margin-top: 50px;
}

.subTitle {
  font-weight: bold;
  font-size: 17px;
  margin-top: 30px;
}

.body {
  font-size: 16px;
  margin-top: 20px;
  display: flex;
  line-height: 1.4;
  padding-right: 60px;
}

.storeLinks {
  display: flex;
  margin-top: 20px;
}

.benefitContentContainer {
  display: flex;
}

.imgLogin {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 1088px;
  height: 208px;
}

.imgNoCode {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 1540px;
  height: 264px;
}

.contentContainer {
  width: 664px;
}

@media (max-width: 1023px) {
  .root {
    width: 100%;
    padding: 0px 20px 20px 20px;
  }

  .contentContainer {
    width: 405px;
  }

  .body {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .root {
    padding: 0;
  }
  .seg {
    flex-direction: column;
  }
  .content {
    padding: 20px;
  }
  .title {
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0px;
    color: #5f6062;
    margin-top: 0;
    text-align: left;
  }
  .stepTitleContainer {
    display: none;
  }

  .step {
    margin-bottom: 12px;
    line-height: 22px;
  }
  .stepTwo {
    margin-bottom: 12px;
    line-height: 22px;
  }

  .contentContainer {
    width: 100%;
    text-align: inherit;
    padding: 20px;
  }

  .body {
    display: block;
    padding: 0;
  }

  .imgLogin {
    width: auto;
    margin-bottom: 10px;
  }

  .imgNoCode {
    width: auto;
    margin-bottom: 10px;
  }

  .benefitContentContainer {
    display: block;
  }

  .storeLinks {
    justify-content: space-evenly;
  }
}

@media (max-width: 320px) {
  .content {
    padding: 20px 8px 20px 8px;
  }
}
