.root {
  padding-bottom: 30px;
  border-bottom: 1px solid #e1e3e4;
}

.img {
  height: 50px;
  width: 100%;
  margin-bottom: 20px;
}

.pools {
  margin-bottom: 20px;
}

.item {
  margin-top: 15px;
}
