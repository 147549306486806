.root {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 338px;
}

@media (max-width: 767px) {
  .root {
    width: 320px;
  }
}

@media (max-width: 320px) {
  .root {
    width: 260px;
  }
}
